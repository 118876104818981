<template>
  <div class="va-row">
    <div class="flex md12 xs12">
      <vuestic-widget
        :headerText="'create-extension.create-extension' | translate"
      >
        <half-circle-spinner
          :animation-duration="1500"
          :size="50"
          color="#008ecf"
          v-if="loading"
        />
        <div class="" v-else>
          <div class="row mb-4">
            <div class="col-12 col-lg-6">
              <field
                :label="$t('create-extension.form.name')"
                v-model="model.name"
                :error="errors.name"
              ></field>
            </div>
            <div class="col-12 col-lg-6">
              <field
                :label="$t('create-extension.form.email')"
                v-model="model.email"
                :error="errors.email"
              ></field>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-lg-6">
              <field
                type="number"
                :label="$t('create-extension.form.extension-number')"
                v-model="model.ext"
                :error="errors.ext"
              ></field>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-lg-6">
              <field
                :label="$t('create-extension.form.inbound-calls')"
                :error="errors.inboundCalls"
              >
                <vuestic-switch v-model="model.inbound">
                  <span slot="trueTitle">{{
                    $t('create-extension.form.enable')
                  }}</span>
                  <span slot="falseTitle">{{
                    $t('create-extension.form.disable')
                  }}</span>
                </vuestic-switch></field
              >
            </div>
            <div class="col-12 col-lg-6">
              <field
                :label="$t('create-extension.form.outbound-calls')"
                :error="errors.outboundCalls"
              >
                <vuestic-switch v-model="model.outbound">
                  <span slot="trueTitle">{{
                    $t('create-extension.form.enable')
                  }}</span>
                  <span slot="falseTitle">{{
                    $t('create-extension.form.disable')
                  }}</span>
                </vuestic-switch></field
              >
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-lg-6">
              <field
                :label="$t('create-extension.form.voicemail')"
                :error="errors.voicemail"
              >
                <vuestic-switch v-model="model.voicemail">
                  <span slot="trueTitle">{{
                    $t('create-extension.form.yes')
                  }}</span>
                  <span slot="falseTitle">{{
                    $t('create-extension.form.no')
                  }}</span>
                </vuestic-switch></field
              >
            </div>
            <div class="col-12 col-lg-6">
              <field
                type="number"
                :label="$t('create-extension.form.pin')"
                v-model="model.pin"
                :error="errors.pin"
              ></field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <button
                @click="createExtension"
                class="btn btn-primary btn-sm btn-with-icon-sm rounded"
                :disabled="createLoading"
              >
                <half-circle-spinner
                  slot="loading"
                  :animation-duration="1500"
                  :size="20"
                  color="#fff"
                  v-if="createLoading"
                />
                <span v-else class="btn-with-icon-content">
                  <i class="ion-md-add ion"></i>
                  {{ $t('create-extension.form.create') }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </vuestic-widget>
    </div>
  </div>
</template>
<script>
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import Field from '../../../elements/Field.vue'
import { object, string } from 'yup'
import ExtensionService from '../../../services/extension.service'
import { yupToKV } from '../../../utils/yup'

export default {
  components: {
    HalfCircleSpinner,
    Field
  },
  data() {
    return {
      createLoading: false,
      loading: false,
      model: {
        name: '',
        email: '',
        ext: '',
        inbound: true,
        outbound: true,
        voicemail: false,
        pin: Math.floor(1000 + Math.random() * 9000).toString()
      },
      errors: {}
    }
  },
  computed: {
    createSchema() {
      return object().shape({
        name: string()
          .trim()
          .matches(
            /^[a-zA-Z0-9\s\p{P}]+$/u,
            this.$t('create-extension.errors.invalid.name')
          )
          .required(this.$t('create-extension.errors.required.name')),
        email: string()
          .email(this.$t('create-extension.errors.invalid.email'))
          .required(this.$t('create-extension.errors.required.email')),
        ext: string()
          .min(3, this.$t('create-extension.errors.invalid.extension-number'))
          .required(
            this.$t('create-extension.errors.required.extension-number')
          ),
        pin: string()
          .trim()
          .matches(/^\d{4}$/, this.$t('create-extension.errors.invalid.pin'))
          .required(this.$t('create-extension.errors.required.pin'))
      })
    }
  },
  async mounted() {
    this.recommendExtensionNumber()
  },
  methods: {
    async createExtension() {
      this.createLoading = true
      await this.createSchema
        .validate(this.model, { abortEarly: false })
        .catch(err => {
          this.errors = yupToKV(err)
        })

      if (this.createSchema.isValidSync(this.model)) {
        this.errors = {}

        try {
          await ExtensionService.createExtension({
            ...this.model,
            ext: +this.model.ext,
            inbound: +this.model.inbound,
            outbound: +this.model.outbound,
            voicemail: +this.model.voicemail
          })
          this.toast(this.$t('create-extension.toasts.created-successfully'))
          this.$router.push({ name: 'all-extensions' })
        } catch (error) {
          this.toast(error.response.data.message, { type: 'error' })
        } finally {
          this.createLoading = false
        }
      } else {
        this.createLoading = false
      }
    },
    async recommendExtensionNumber() {
      try {
        this.loading = true
        const recommendedExtension = await ExtensionService.getRecommendedExtensionNumber()
        this.model.ext = recommendedExtension.toString()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
